<template>
  <div class="text-center">
    <v-dialog
      v-model="showDialog"
      max-width="1366"
      origin="top center"
      class="dialog pa-0"
      persistent
      scrollable
    >
      <v-card :loading="isSaving">
        <v-card-title class="pa-0 z-index-front">
          <v-toolbar light elevation="1" class="">
            <v-toolbar-title>
              {{ isEditMode ? $t("edit_invoice") : $t("create_invoice") }}
              <v-chip
                v-if="isEditMode"
                class="ma-2 text-uppercase"
                filter
                :text-color="statusColor.color"
                :color="statusColor.background"
              >
                {{ formData.status }}
              </v-chip>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="onClose" :disabled="isSaving">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="px-0 pb-0">
          <v-container class="grey lighten-4 pa-0">
            <v-form ref="invoiceForm">
              <v-card class="pa-0 grey lighten-4" outlined tile :loading="isSaving">
                <v-row no-gutters>
                  <v-col cols="12" sm="12" md="4" class="pa-4">
                    <v-card class="grey lighten-4" outlined tile>
                      <label
                        class="labelcolor--text text-uppercase"
                        for="formdata-invoice-number"
                        >{{ $t("invoice_no") }}</label
                      >
                      <v-text-field
                        v-model="formData.document_number"
                        :label="$t('enter_invoice_no')"
                        solo
                        class="app-theme-input-border"
                        flat
                        required
                        dense
                        outlined
                        id="formdata-invoice-number"
                        :rules="[(v) => !!v || $t('field_required')]"
                      ></v-text-field>

                      <label
                        class="labelcolor--text text-uppercase"
                        for="formdata-order-number"
                        >{{ $t("order_number") }}</label
                      >
                      <v-text-field
                        v-model="formData.order_number"
                        :label="$t('invoice_enter_order_number')"
                        solo
                        class="app-theme-input-border"
                        flat
                        required
                        dense
                        id="formdata-order-number"
                        outlined
                      ></v-text-field>

                      <label
                        class="labelcolor--text text-uppercase"
                        for="formdata-customer"
                        >{{ $t("customer") }}</label
                      >
                      <v-autocomplete
                        id="formdata-customer"
                        v-model="formData.customer"
                        :loading="isCustomerDataLoading"
                        :items="customerDataComputed"
                        :search-input.sync="searchCustomerText"
                        clearable
                        dense
                        solo
                        flat
                        outlined
                        item-text="text"
                        item-value="value"
                        :rules="[v => !!v || $t('field_required')]"
                        class="app-theme-input-border"
                        @change="onChangeCustomer"
                      ></v-autocomplete>

                      <div v-if="homeCurrency !== customerCurrency" class="mb-4">
                        <label
                          class="labelcolor--text text-uppercase"
                          for="formdata-currency-rate"
                          >{{ $t("invoice_currency_rate") }}</label
                        >
                        <v-text-field
                          v-model="formData.currency_rate"
                          :label="$t('invoice_currency_rate')"
                          :rules="[(v) => !!v && v > 0 || $t('field_required')]"
                          solo
                          class="app-theme-input-border align-center"
                          outlined
                          flat
                          required
                          dense
                          hide-details
                          id="formdata-currency-rate"
                        >
                          <template v-slot:prepend>
                            <span>&nbsp;1&nbsp;</span>
                            <span>{{ homeCurrency }}</span>
                            <span>&nbsp;=</span>
                          </template>
                          <template v-slot:append-outer>
                            <span>{{ customerCurrency }}</span>
                          </template>
                        </v-text-field>
                      </div>

                      <div class="d-sm-flex justify-sm-space-between justify-end mb-sm-0 mb-2">
                        <div>
                          <label class="labelcolor--text text-uppercase" for="formdata-invoice-date">{{ $t("invoice_date") }}</label
                          >
                          <date-picker v-model="formData.issued_at" id="formdata-invoice-date" :rules="[(v) => !!v || $t('field_required')]"></date-picker>
                        </div>
                        <v-spacer class="d-sm-inline d-none">&nbsp;&nbsp;&nbsp;</v-spacer>
                        <div>
                          <label class="labelcolor--text text-uppercase" for="formdata-due-date">{{ $t("due_date") }}</label>
                          <date-picker v-model="formData.due_date" id="formdata-due-date" :rules="[(v) => !!v || $t('field_required')]"></date-picker>
                        </div>
                      </div>

                      <label
                        class="form-label text-uppercase"
                        for="formdata-category"
                        >{{ $t("category") }}</label
                      >
                      <v-select
                        v-model="formData.category"
                        :items="categoryDataList"
                        :rules="[(v) => !!v || $t('field_required')]"
                        id="formdata-category"
                        solo
                        flat
                        class="app-theme-input-border"
                        dense
                        outlined
                        item-value="value"
                        item-text="text"
                        :loading="isCategoryDataLoading"
                      >
                      </v-select>

                      <label
                        class="labelcolor--text text-uppercase"
                        for="formdata-notes"
                        >{{ $t("notes") }}</label
                      >
                      <v-textarea
                        v-model="formData.notes"
                        solo
                        :label="$t('type_notes_here') + '...'"
                        class="app-theme-input-border"
                        flat
                        id="formdata-notes"
                        rows="2"
                        hide-details
                        outlined
                      ></v-textarea>

                      <v-spacer class="mt-4"></v-spacer>

                      <div v-if="isQBOEnabled" class="">
                        <label
                          class="form-label text-uppercase"
                          for="formdata-terms"
                          >{{ $t("terms") }}</label
                        >
                        <v-select
                          v-model="formData.qbo_term_ref"
                          :items="termsDataList"
                          id="formdata-terms"
                          solo
                          flat
                          class="app-theme-input-border"
                          dense
                          outlined
                        >
                        </v-select>

                        <label
                          class="labelcolor--text text-uppercase"
                          for="formdata-bill-email"
                          >{{ $t("customer_email") }}</label
                        >
                        <v-text-field
                          v-model="formData.bill_to_email"
                          :label="$t('enter_email')"
                          solo
                          class="app-theme-input-border"
                          flat
                          required
                          dense
                          id="formdata-bill-email"
                          :rules="[(v) => !!v || $t('field_required')]"
                          outlined
                        ></v-text-field>

                        <label
                          class="labelcolor--text text-uppercase"
                          for="formdata-message-statement"
                          >{{ $t("invoice_message_statement") }}</label>
                        <v-textarea
                          v-model="formData.qbo_private_note"
                          :label="$t('invoice_enter_message')"
                          solo
                          class="app-theme-input-border"
                          flat
                          id="formdata-message-statement"
                          rows="2"
                          hide-details
                          outlined
                        ></v-textarea>
                        <v-spacer>&nbsp;</v-spacer>

                        <label
                          class="labelcolor--text text-uppercase"
                          for="formdata-billing-address"
                          >{{ $t("billing_address") }}</label>
                        <v-textarea
                          v-model="formData.bill_to_address"
                          solo
                          class="app-theme-input-border"
                          flat
                          id="formdata-billing-address"
                          rows="2"
                          hide-details
                          outlined
                        ></v-textarea>

                      </div>

                    </v-card>
                  </v-col>
                  <v-col class="white pa-4">
                    <v-card class="" flat>
                      <div v-if="!getIsMobileLayout">
                        <table
                          class="mb-0 w-100"
                          dense
                          v-if="formData.items.length"
                        >
                          <thead class="grey lighten-3">
                            <tr>
                              <th
                                class="pl-3 text-left text-uppercase text-subtitle-2 th--text py-2"
                                width="25%"
                              >
                                {{ $t("item") }}
                              </th>
                              <th class="text-left text-uppercase text-subtitle-2 font-weight-black">
                                {{ $t('description') }}
                              </th>
                              <th
                                class="text-center text-uppercase text-subtitle-2 th--text"
                                width="10%"
                              >
                                {{ $t("quantity") }}
                              </th>
                              <th
                                class="text-center text-uppercase text-subtitle-2 th--text"
                                width="10%"
                              >
                                {{ $t("price") }}
                              </th>
                              <th
                                class="text-center text-uppercase text-subtitle-2  th--text"
                                width="10%"
                              >
                                {{ $t("amount") }}
                              </th>
                              <th width="5%">&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="(item, index) in formData.items">
                              <tr :key="`item_index_${index}`">
                                <td class="px-3 pt-2">
                                  {{ item.item.name }}
                                  <v-menu
                                    :close-on-content-click="false"
                                    offset-y
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <div>
                                        <a
                                          color="primary"
                                          dark
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          {{ $t('edit_income_account') }}
                                        </a>
                                      </div>
                                    </template>

                                    <v-card width="300">
                                      <v-card-text>
                                        <p>{{ $t('edit_income_account_message') }}</p>
                                        <label :for="`account-${item.item.id}`" class="labelcolor--text">{{ $t('account') }}</label>
                                        <v-autocomplete
                                          v-model="item.item.de_income_account_value"
                                          :items="incomeAccountLists"
                                          :id="`account-${item.item.id}`"
                                          clearable
                                          dense
                                          outlined
                                        ></v-autocomplete>
                                      </v-card-text>
                                    </v-card>
                                  </v-menu>
                                </td>
                                <td class="pr-3 pt-2">
                                  <v-textarea
                                    v-model="item.description"
                                    solo
                                    class="app-theme-input-border"
                                    flat
                                    dense
                                    row-height="1"
                                    auto-grow
                                    hide-details
                                  ></v-textarea>
                                </td>
                                <td class="px-0 pr-3">
                                  <v-text-field
                                    v-model.number="item.quantity"
                                    solo
                                    flat
                                    @input="computeItemTotalPerIndex(index)"
                                    required
                                    dense
                                    type="number"
                                    class="app-theme-input-border text-right"
                                    hide-details
                                  ></v-text-field>
                                </td>
                                <td class="px-0 pr-3">
                                  <v-text-field
                                    v-model.number="item.price"
                                    @input="computeItemTotalPerIndex(index)"
                                    solo
                                    flat
                                    required
                                    dense
                                    type="number"
                                    class="app-theme-input-border text-right"
                                    hide-details
                                  ></v-text-field>
                                </td>
                                <td class="px-0 pr-3">
                                  <v-text-field
                                    v-model.number="item.total"
                                    solo
                                    flat
                                    required
                                    hide-details
                                    dense
                                    readonly
                                    class="app-theme-input-border text-right"
                                  ></v-text-field>
                                </td>
                                <td>
                                  <v-btn
                                    icon
                                    color="red"
                                    small
                                    @click="onRemoveItem(index)"
                                  >
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                        <div v-if="showSelectItem" class="mt-6 d-flex justify-space-between">
                          <item-async-autocomplete
                            :income="true"
                            v-model="item"
                            @change="onItemChange"
                          ></item-async-autocomplete>
                          <v-btn class="text-capitalize" text color="red" @click="showSelectItem = false" icon>
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </div>
                        <v-btn
                          outlined
                          text
                          small
                          @click="onAddItem"
                          color="#0889a0"
                          class="
                            my-4
                            labelcolor--text
                            font-weight-bold
                            py-4
                            text-capitalize
                          "
                        >
                          <v-icon>mdi-plus</v-icon>
                          {{ $t("add_item") }}
                        </v-btn>
                        <v-divider />
                      </div>

                      <v-sheet
                        color="transparent"
                        class="d-flex flex-column d-md-none d-lg-none d-xl-none mb-2"
                        v-if="getIsMobileLayout"
                      >
                        <v-subheader class="text-h6 labelcolor--text">{{
                          $t("side_menu_items")
                        }}</v-subheader>
                        <v-card
                          outlined
                          flat
                          class="mx-2 mb-4"
                          v-for="(item, index) in formData.items"
                          :key="`item_index_${index}`"
                        >
                          <v-toolbar flat>
                            <div>
                              <span>{{ item.item.name }}</span>
                              <div>
                                <v-menu
                                  :close-on-content-click="false"
                                  offset-y
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <div>
                                      <a
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        {{ $t('edit_income_account') }}
                                      </a>
                                    </div>
                                  </template>

                                  <v-card width="300">
                                    <v-card-text>
                                      <p>{{ $t('edit_income_account_message') }}</p>
                                      <label :for="`account-${item.item.id}`" class="labelcolor--text">{{ $t('account') }}</label>
                                      <v-autocomplete
                                        v-model="item.item.de_income_account_value"
                                        :items="incomeAccountLists"
                                        :id="`account-${item.item.id}`"
                                        clearable
                                        dense
                                        outlined
                                      ></v-autocomplete>
                                    </v-card-text>
                                  </v-card>
                                </v-menu>
                              </div>
                            </div>
                            <v-spacer />
                            <v-btn
                              small
                              icon
                              color="red"
                              @click="onRemoveItem(index)"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </v-toolbar>
                          <div class="d-flex px-2 py-1">
                            <div>
                              <label
                                class="form-label text-uppercase"
                                for="formdata-quantity"
                                >{{ $t("quantity") }}</label
                              >
                              <v-text-field
                                v-model.number="item.quantity"
                                solo
                                flat
                                @input="computeItemTotalPerIndex(index)"
                                :rules="[
                                  (v) => (v && v >= 1) || 'Minimum is 1',
                                ]"
                                required
                                dense
                                type="number"
                                class="app-theme-input-border text-right"
                                hide-details
                              />
                            </div>
                            <div class="mx-1">
                              <label
                                class="form-label text-uppercase"
                                for="formdata-quantity"
                                >{{ $t("price") }}</label
                              >
                              <v-text-field
                                v-model.number="item.price"
                                @input="computeItemTotalPerIndex(index)"
                                solo
                                flat
                                required
                                dense
                                type="number"
                                class="app-theme-input-border text-right"
                                hide-details
                              />
                            </div>
                            <div>
                              <label
                                class="form-label text-uppercase"
                                for="formdata-quantity"
                                >{{ $t("total") }}</label
                              >
                              <v-text-field
                                v-model.number="item.total"
                                solo
                                flat
                                required
                                hide-details
                                dense
                                readonly
                                class="app-theme-input-border text-right"
                              />
                            </div>
                          </div>
                          <v-textarea
                            v-model="item.description"
                            solo
                            class="app-theme-input-border mx-2"
                            flat
                            dense
                            placeholder="Description..."
                            rows="2"
                          ></v-textarea>
                        </v-card>
                        <div v-if="showSelectItem" class="mt-4 d-flex justify-space-between">
                          <item-async-autocomplete
                            :income="true"
                            v-model="item"
                            @change="onItemChange"
                          ></item-async-autocomplete>
                          <v-btn class="text-capitalize" text color="red" @click="showSelectItem = false" icon>
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </div>
                        <div class="mx-2 mb-4">
                          <v-btn block text outlined color="primary" @click="onAddItem">
                            {{ $t('add_item') }}
                          </v-btn>
                        </div>
                      </v-sheet>

                      <v-row class="mt-4">
                        <v-col cols="12" sm="6">
                          <label class="text-uppercase labelcolor--text">{{ $t('attachments') }}</label>
                          <v-card
                            class="text-center pa-5 border-dashed"
                            width="400"
                            flat
                            @dragover="dragover"
                            @dragleave="dragleave"
                            @drop="drop"
                            :class="[isBrowseFileHovered ? 'grey lighten-2' : '']"
                          >
                            <v-card-text class="pa-0">
                              <v-list
                                subheader
                                two-line
                                v-if="fileList.length || attachedFiles.length"
                              >
                                <v-subheader class="text-subtitle-1">{{ $t('files') }}</v-subheader>

                                <v-list-item
                                  v-for="attachedFile in attachedFiles"
                                  :key="`attached-${attachedFile.id}`"
                                  dense
                                  class="pa-0"
                                >
                                  <v-list-item-avatar height="20" width="20">
                                    <v-icon
                                      color="#0889a0"
                                      v-text="'mdi-image'"
                                    ></v-icon>
                                  </v-list-item-avatar>

                                  <v-list-item-content>
                                    <v-list-item-title v-text="attachedFile.filename" class="text-left"></v-list-item-title>
                                  </v-list-item-content>

                                  <v-list-item-action class="flex-row">
                                    <v-btn icon :title="$t('delete')">
                                      <v-icon color="red">mdi-delete</v-icon>
                                    </v-btn>
                                    <v-btn icon :title="$t('download')">
                                      <v-icon color="primary">mdi-download</v-icon>
                                    </v-btn>
                                  </v-list-item-action>
                                </v-list-item>

                                <v-list-item
                                  v-for="(file, index) in fileList"
                                  :key="file.name"
                                  dense
                                  class="pa-0"
                                >
                                  <v-list-item-avatar height="20" width="20">
                                    <v-icon
                                      color="#0889a0"
                                      v-text="'mdi-image'"
                                    ></v-icon>
                                  </v-list-item-avatar>

                                  <v-list-item-content>
                                    <v-list-item-title v-text="file.name" class="text-left"></v-list-item-title>
                                  </v-list-item-content>

                                  <v-list-item-action>
                                    <v-btn icon @click="removeFile(index)">
                                      <v-icon color="red">mdi-close</v-icon>
                                    </v-btn>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list>
                              <input
                                type="file"
                                multiple
                                name="filelist[images][]"
                                id="file-input-expense" 
                                class="display-none"
                                @change="onFileBrowseChange"
                                ref="fileExpenseAttachment"
                                accept=".ai,.csv,.doc,.docx,.eps,.gif,.jpeg,.jpg,.ods,.pdf,.png,.rtf,.tif,.txt,.xls,.xlsx,.xml"
                              />
                              <label for="file-input-expense" class="form-label pa-5" title="Click to browse file">
                                <h6 class="pa-5" style="border: 1px dashed;">{{ $t('browse_drop_image') }}</h6>
                              </label>
                              <!-- <v-btn small outlined text class="text-capitalize pa-3" color="#0889a0" @click="onUploadFile">
                                <v-icon>mdi-upload</v-icon>
                                {{ $t('upload') }}
                              </v-btn> -->
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-row>
                            <v-col class="text-right" cols="8">{{
                              $t("subtotal")
                            }}</v-col>
                            <v-col class="text-right">{{ currencyFormat(subTotal) }}</v-col>
                          </v-row>
                          <v-divider></v-divider>
                          <v-row class="my-1">
                            <v-col class="text-right" cols="8">{{ $t("tax") }}</v-col>
                            <v-col class="text-right">{{ currencyFormat(taxTotal) }}</v-col>
                          </v-row>
                          <v-divider></v-divider>
                          <v-row>
                            <v-col class="text-right" cols="8">{{ $t("total") }}</v-col>
                            <v-col class="text-right">
                              {{ currencyFormat(itemTotal) }}
                            </v-col>
                          </v-row>
                          <v-row v-if="homeCurrency !== customerCurrency">
                            <v-col cols="12" sm="2"></v-col>
                            <v-col
                              class="text-subtitle-1"
                            >
                            <p>{{ $t("currency_conversion") }}:</p>
                            <p class="text-right">
                             {{ numberToCurrency(itemTotal / formData.currency_rate, homeCurrency) }} ({{ homeCurrency }}) {{ $t('at') }} 
                             <input type="number" :value="formData.currency_rate" readonly id="conversion_rate_input" class="px-2" />
                            </p>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-start">
          <v-btn @click="onSaveForm" class="text-capitalize btn-primary" :loading="isSaving" v-if="!isEditMode">{{ $t("save") }}</v-btn>
          <v-btn @click="onSaveForm" class="text-capitalize btn-primary" :loading="isSaving" v-if="isEditMode">{{ $t("update") }}</v-btn>
          <v-btn text class="text-capitalize" @click="onClose" outlined :disabled="isSaving">{{ $t("cancel") }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <v-snackbar
        timeout="5000"
        vertical
        :color="snackbarOption.color"
        v-model="showSnackbar"
        bottom
      >
      <v-icon v-if="snackbarOption.icon">{{ snackbarOption.icon }}</v-icon> {{ snackbarOption.message }}
      </v-snackbar>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters, mapState } from "vuex";
import DatePicker from "./DatePicker.vue";
import ItemAsyncAutocomplete from "./ItemAsyncAutocomplete.vue";
import AkauntingService from "@/services/akaunting/akaunting.service";
import { debounce } from '@/utils/general';
import akauntingService from '@/services/akaunting/akaunting.service';

import currencies from '../data/currencies.json';

export default {
  components: {
    DatePicker,
    ItemAsyncAutocomplete,
  },
  props: ["open", "isEditMode", "formValues"],
  data() {
    return {
      formData: {
        document_number: "",
        order_number: "",
        notes: "",
        issued_at: "",
        due_date: "",
        category: "",
        customer: null,
        items: [],
        qbo_term_ref: null,
        bill_to_email: null,
        qbo_private_note: null,
        bill_to_address: null,
        currency_rate: 1,
        home_currency: null,
        attachment: [],
      },
      customerData: [],
      item: null,
      itemsData: [],
      defaultForm: {},
      categoryData: [],
      isCategoryDataLoading: false,
      isCustomerDataLoading: false,
      isSaving: false,
      searchCustomerText: null,
      snackbarOption: {},
			showSnackbar: false,
      termsDataList: [],
      fileList: [],
			isBrowseFileHovered: false,
      showSelectItem: false,
      incomeAccountLists: [],
    };
  },

  created() {
    this.defaultForm = JSON.parse(JSON.stringify({ ...this.formData }));
    this.fetchIncomeCategory();
    this.fetchCustomerList();
    this.fetchTerms();
  },

  watch: {
    searchCustomerText: debounce(function () {
      this.searchCustomer();
    }, 600),

    open(isTrue) {
      if(isTrue) {
        this.getDEaccounts();
        if (!this.isEditMode) {
          const currentDate = this.moment(new Date(), "YYYY-MM-DD");
          this.formData = {
            ...this.defaultForm,
            issued_at: currentDate.format("YYYY-MM-DD"),
            due_date: currentDate.format("YYYY-MM-DD"),
          };
          // this.$refs.invoiceForm.reset();
        } else if(this.isEditMode) {
          const {
            issued_at,
            due_at,
            document_number,
            order_number,
            category_ref,
            contact_ref,
            notes,
            status,            
            currency_code,
            currency_rate,
            id,
            qbo_id,
            items_ref,
            qbo_term_ref,
            bill_to_address,
            bill_to_email,
            qbo_private_note
          } = this.formValues;
          const _items_ref = JSON.parse(items_ref) || [];
          this.formData = {
            id,
            qbo_id,
            issued_at: moment(issued_at, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            due_date: moment(due_at, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            document_number,
            order_number,
            notes,
            category: category_ref,
            customer: contact_ref,
            items: _items_ref.map(data => ({
              item: data.item,
              quantity: data.quantity,
              price: data.price,
              total: data.total,
              description: data.description,
            })),
            qbo_term_ref,
            bill_to_email,
            qbo_private_note,
            bill_to_address,
            status,
            home_currency: currency_code,
            currency_rate,
          };
          this.customerData = [JSON.parse(contact_ref)];
        }
      }
    }
  },

  computed: {
    ...mapGetters("accounting", ["isQBOEnabled", 'homeCurrency']),
    ...mapState('accounting', ['companyInformation']),
    ...mapGetters(['getIsMobileLayout']),
    customerDataComputed: {
      get() {
        return this.customerData.map(function ({
          id,
          name,
          email,
          currency_code,
          qbo_realm_id,
          qbo_id,
          qbo_values,
          origin,
        }) {
          return {
            text: name,
            value: JSON.stringify({
              id,
              name,
              email,
              currency_code,
              qbo_realm_id,
              qbo_id,
              qbo_values,
              origin,
            }),
          };
        });
      },
    },
    showDialog: {
      get() {
        return this.open;
      },
      set() {
        this.$emit("toggle");
      },
    },

    subTotal() {
      if (this.formData.items && this.formData.items.length) {
        return this.formData.items.reduce(
          (c, n) => c + Number(n.price || 0) * Number(n.quantity || 0),
          0
        );
      }
      return 0;
    },

    taxTotal() {
      // if(this.formData.items && this.formData.items.length) {
      // 	return this.formData.items.reduce((c, n) => c + Number(n.tax) ,0);
      // }
      return 0;
    },

    itemTotal() {
      return this.subTotal + this.taxTotal;
    },

    statusColor() {
      if (this.formData && this.formData.status) {
        switch (this.formData.status.toLowerCase()) {
          case "overdue":
            return { color: "#e32d2d", background: "#fff2f2" };
          case "partially paid":
            return { color: "#7cdab8", background: "#ecffed" };
          case "open":
            return { color: "#0171a1", background: "#cbf1ff" };
          case "draft":
            return { color: "#819fb2", background: "#efefef" };
        }
      }
      return { color: "#fff", background: "#fff" };
    },

    categoryDataList: {
      get() {
        return this.categoryData.map((category) => ({
          text: category.name,
          value: JSON.stringify({
            id: category.id,
            name: category.name,
            type: category.type,
          }),
        }));
      },
    },

    customerCurrency() {
      const customer = JSON.parse(this.formData.customer) || {};
      return customer?.currency_code || 'USD';
    },

    customerCurrencySymbol() {
      const currency = currencies.find( record => record.code === this.customerCurrency );
      return currency?.symbolNative || '$';
    },

    attachedFiles() {
      if(this.isEditMode && this.formValues) {
        return this.formValues.attachment;
      }
      return [];
    },

  },

  methods: {
    moment,
    ...mapActions("accounting", ["createInvoiceForm", 'getCustomersData', 'updateInvoice']),

    currencyFormat(amount) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: this.customerCurrency }).format(amount);
    },

    numberToCurrency(number, currency) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(number);
    },

    fetchTerms() {
      if(this.isQBOEnabled === 0) {
        return;
      }
      akauntingService.getTerms()
        .then(({data}) => {
          this.termsDataList = data.map(record => ({text: record.Name, value: JSON.stringify(record)}));
        })
        .catch(error => {
          console.log(error);
          this.termsDataList  = [];
        })
    },

    computeItemTotalPerIndex(index){
			const {price, quantity} = this.formData.items[index]
			this.formData.items[index].total = price * quantity
		},

    onClose() {
      this.$refs.invoiceForm.reset();
      this.formData = {
        ...this.defaultForm,
      };
      this.fileList = [];
      this.showSelectItem = false;
      this.$emit("toggle");
    },

    async fetchCustomerList() {
      if(this.isCustomerDataLoading) {
        return;
      }
      this.isCustomerDataLoading = true;
      try {
        const { data } = await this.getCustomersData({ page: 1, limit: 10 });
        this.customerData = data || [];
      } catch(error) {
      } finally { 
        this.isCustomerDataLoading = false;
      }
    },

    async searchCustomer() {
      const search = this.searchCustomerText;
      if(this.formData.customer) {
        const customer = JSON.parse(this.formData.customer);
        if(customer && customer.name === search) {
          return;
        }
      }
      if(this.isCustomerDataLoading || !search) {
        return;
      }
      this.isCustomerDataLoading = true;
      try {
        const { data } = await this.getCustomersData({ page: 1, limit: 10, search });
        this.customerData = data || [];
      } catch(error) {
      } finally { 
        this.isCustomerDataLoading = false;
      }
    },

    onChangeCustomer(value) {
      const customer = JSON.parse(value);
      if(customer) {
        const formData = {...this.formData};
        if(this.homeCurrency === this.customerCurrency) {
          formData.currency_rate = 1;
        }
        this.formData = {
          ...formData,
          bill_to_email: customer.email,
        };
      }
    },

    fetchIncomeCategory() {
      this.isCategoryDataLoading = true;
      AkauntingService.getAkauntingIncomeCategory()
        .then((response) => {
          this.categoryData = response?.data?.data?.data || [];
          this.isCategoryDataLoading = false;
        })
        .catch((error) => {
          //Do nothing
          this.isCategoryDataLoading = false;
        });
    },

    async onSaveForm() {
      if (this.isSaving)  {
        return;
      }
      const validated = this.$refs.invoiceForm.validate();
      if (validated) {
        this.isSaving = true;
        try {
          const { issued_at, due_date } = this.formData;
          const amount = this.subTotal + this.taxTotal;
          const form = {
            ...this.formData,
            items: JSON.stringify(this.formData.items || []),
            issued_at: moment(issued_at, "YYYY-MM-DD").format(
              "YYYY-MM-DD h:mm:ss"
            ),
            due_at: moment(due_date, "YYYY-MM-DD").format("YYYY-MM-DD h:mm:ss"),
            qbo_enabled: this.isQBOEnabled,
            amount,
            home_currency: this.homeCurrency,
            currency_rate: this.customerCurrency === this.homeCurrency ? 1 : this.formData.currency_rate,
          };

          const _formData = new FormData();
          Object.keys(form).forEach(key => {
            // console.log(key, form[key]);
            _formData.append(key, form[key]);
          });

          for(let i=0, count = this.fileList.length; i<count; i++) {
            _formData.append(`attachment[${i}]`, this.fileList[i]);
          }

          const data = this.isEditMode ? await this.updateInvoice({id: form.id, payload: _formData}) : await this.createInvoiceForm(_formData);

          const message = data.message || this.isEditMode ? 'Data was successfully updated.' : 'Data was successfully saved.';

          this.snackbarOption = {
            icon: "mdi-check",
            color: "success",
            message,
          };

          this.$refs.invoiceForm.reset();
          this.formData = {
            ...this.defaultForm,
          };
          this.fileList = [];
          this.$emit("toggle", { created: true, message });
        } catch (error) {
          console.log(error);

          const { data } = error.response || { data: {} };

          this.snackbarOption = {
            icon: "mdi-alert-circle",
            color: "error",
            message: data.message || "Could not save the data.",
          };
        } finally {
          this.showSnackbar = true;
          this.isSaving = false;
        }
      }
    },

    onAddItem() {
      /* this.formData.items.push({
        item: {
          id: 0,
          name: "",
          de_income_account_value: {},
          qbo_item_id: 0,
          qbo_item_type: "",
        },
        description: "",
        quantity: 1,
        price: 0.0,
        total: 0.0,
      }); */
      this.showSelectItem = true;
    },

    onItemChange(data = {}) {
      // const items = this.formData.items;
      const quantity = 1;
      const price = data.sale_price || 0;
      const description = data.description || '';
      const _item = {
        item: this.item,
        quantity,
        price,
        total: quantity * price,
        description,
      };
      //  this.$set(this.formData.items, index, item);
      this.formData.items.push(_item);
      this.showSelectItem = false;
      this.item = null;
    },

    onRemoveItem(index) {
      this.formData.items.splice(index, 1);
    },

    onFileBrowseChange() {
      const files = [...this.$refs.fileExpenseAttachment.files];
      this.fileList = files;
    },

    removeFile(i) {
      this.fileList.splice(i, 1);
    },

    dragover(event) {
      event.preventDefault();
      this.isBrowseFileHovered = true;
    },

    dragleave(event) {
      this.isBrowseFileHovered = false;
    },

    drop(event) {
      event.preventDefault();
      this.$refs.fileExpenseAttachment.files = event.dataTransfer.files;
      this.onFileBrowseChange();
      this.isBrowseFileHovered = false;
    },

		onUploadFile() {
			// Upload to server here
			console.log('Uploading...');
		},

    async getDEaccounts() {
			try {
				const { data } = await AkauntingService.getDEaccounts();
				const accounts = data?.data?.data?.accounts || {};
				const filteredKeys = ['Revenue'];
				const _data = [];
				Object.keys(accounts)
					.filter(key => filteredKeys.includes(key))
					.forEach(key => {
						_data.push({ header: key });
						Object.keys(accounts[key]).forEach(k => {
							_data.push({text: accounts[key][k], value: k})
						})
					});
				this.incomeAccountLists = _data;
			} catch(error) {
				apiErrorMessage(error);
			}
		},

  },
};
</script>

<style lang="scss" scoped>
$button-bg-color: #0171a1;
$form-label: #819fb2;
.dialog {
  z-index: 9999;
}
.form-label {
  color: $form-label;
}
.w-100 {
  width: 100%;
}
.display-none {
  display: none;
}

hr {
  border-color: #ebf1f5;
}

th {
  color: $form-label;
  font-weight: bold;
}
.btn-primary {
  background-color: $button-bg-color !important;
  color: #fff !important;
}
.border-dashed {
  border: 1px dashed $form-label !important;
}
.z-index-front {
  z-index: 1;
}
.border-bottom {
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.12);
}
#conversion_rate_input {
  border: 1px solid $form-label;
  width: 50px;
}
</style>
<style lang="scss">
.app-theme-input-border {
  .v-input__slot {
    border: 1px solid #b5d0e1 !important;
  }
  label {
    color: #a3c6dd !important;
  }
}
.text-right {
  input {
    text-align: right;
  }
}
</style>