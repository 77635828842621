<template>
  <div class="widgetContainer__body">
    <v-app class="vue-app">
      <v-main>
        <v-container>

            <v-breadcrumbs :items="breadCrumb" class="pl-0">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  link
                  :href="item.href"
                  @click.prevent="$router.push(item.href)"
                  :disabled="item.disabled"
                >
                  {{ item.text }}
                </v-breadcrumbs-item>
              </template>
              <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
              </template>
            </v-breadcrumbs>
            
            <div class="d-md-flex d-sm-flex justify-space-between">
              <div class="">
                <span class="text-h5 font-weight-bold">
                  <span>{{ $t('invoice') }} #{{ invoiceNumber }}</span>
                  <v-progress-circular v-if="isLoading" color="primary" indeterminate size="24"></v-progress-circular>
                  <v-chip
                    class="ma-2 text-capitalize"
                    filter
                    :text-color="statusColor.color"
                    :color="statusColor.background"
                    v-if="invoiceNumber"
                  >
                    {{ data.status }}
                  </v-chip>
                </span>
              </div>
              <div class="d-flex mb-4">
                <v-btn
                  class="text-capitalize blue--text"
                  outlined
                  @click="onToggleEditModal"
                  :loading="isLoading"
                >
                  {{ $t('edit_invoice') }}
                </v-btn>
                <v-btn
                  class="ml-2 text-capitalize"
                  color="red"
                  outlined
                  @click="onToggleDeleteInvoice"
                  :loading="isLoading"
                >
                  {{ $t('delete') }}
                </v-btn>
              </div>
              
            </div>
            <v-card outlined :loading="isLoading">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="3">
                    <div>
                      <span class="labelcolor--text text-uppercase">{{ $t('customer') }}</span>
                      <p class="subtitle-1 font-weight-medium">{{ data.contact_name }}</p>
                    </div>
                    <div>
                      <span class="labelcolor--text text-uppercase">{{ $t('order_number') }}</span>
                      <p class="subtitle-1 font-weight-medium">{{ data.order_number }}</p>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <div>
                      <span class="labelcolor--text text-uppercase">{{ $t('billing_address') }}</span>
                      <p class="subtitle-1 text-uppercase font-weight-medium">{{ data.bill_to_address || '&nbsp;' }}</p>
                    </div>
                    <div>
                      <span class="labelcolor--text text-uppercase">{{ $t('category') }}</span>
                      <p class="subtitle-1 text-uppercase font-weight-medium">{{ category }}</p>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <div>
                      <span class="labelcolor--text text-uppercase">{{ $t('invoice_date') }}</span>
                      <p class="subtitle-1 text-capitalize font-weight-medium">{{ getMonthNameDayYearFormat(data.issued_at) }}</p>
                    </div>
                    <div>
                      <span class="labelcolor--text text-uppercase">{{ $t('due_date') }}</span>
                      <p class="subtitle-1 text-capitalize font-weight-medium">{{ getMonthNameDayYearFormat(data.due_at) }}</p>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <div>
                      <span class="labelcolor--text text-uppercase">{{ $t('notes') }}</span>
                      <p class="subtitle-1 text-capitalize font-weight-medium">{{ data.notes }}</p>
                    </div>
                    <div>
                      <span class="labelcolor--text text-uppercase">{{ $t('currencyname') }}</span>
                      <p class="subtitle-1 text-capitalize font-weight-medium">{{ currency }}</p>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-toolbar outlined flat>
                <v-tabs v-model="tabStatus" @change="onChangeTab">
                  <v-tab class="text-capitalize">{{ $t('summary') }}</v-tab>
                  <!-- <v-tab class="text-capitalize">{{ $t('payments') }}</v-tab> -->
                </v-tabs>
              </v-toolbar>

              <v-card-text v-if="tabStatus === 0">
                <v-row>
                  <v-col cols="12" class="border-right" md="12" sm="12">
                    <v-simple-table class="v-table-middle-align">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-uppercase labelcolor--text font-weight-bold">{{ $t('item') }}</th>
                            <th class="text-uppercase labelcolor--text font-weight-bold">{{ $t('description') }}</th>
                            <th class="text-uppercase labelcolor--text text-center font-weight-bold">{{ $t('quantity') }}</th>
                            <th class="text-uppercase labelcolor--text text-right font-weight-bold">{{ $t('price') }}</th>
                            <th class="text-uppercase labelcolor--text text-right font-weight-bold">{{ $t('total') }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in items"
                            :key="index"
                          >
                            <td>{{ item.item.name }}</td>
                            <td>{{ item.description }}</td>
                            <td class="text-center">{{ item.quantity }}</td>
                            <td class="text-right">{{ item.price }}</td>
                            <td class="text-right">{{ new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.total) }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-row class="mt-4">
                      <v-col cols="12" sm="7">
                        <v-card
                          class="text-center border-dashed"
                          width="400"
                          flat
                        >
                          <v-card-text class="pa-0">
                            <v-list
                              subheader
                              two-line
                              v-if="attachedFiles.length"
                            >
                              <v-subheader class="text-subtitle-1 labelcolor--text">{{ $t('attachments') }}</v-subheader>

                              <v-list-item
                                v-for="attachedFile in attachedFiles"
                                :key="`attached-${attachedFile.id}`"
                                dense
                                class="pa-0"
                              >
                                <v-list-item-avatar height="20" width="20">
                                  <v-icon
                                    color="#0889a0"
                                    v-text="'mdi-image'"
                                  ></v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <v-list-item-title v-text="attachedFile.filename" class="text-left"></v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action class="flex-row">
                                  <v-btn icon :title="$t('download')">
                                    <v-icon color="primary">mdi-download</v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                            </v-list>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col class="mr-4">
                        <v-row class="">
                          <v-col class="text-right font-weight-bold">{{ $t('subtotal') }}</v-col>
                          <v-col class="text-right font-weight-bold">{{ currencyFormat(subTotal) }}</v-col>
                        </v-row>
                        <v-row class="">
                          <v-col class="text-right font-weight-medium">Tax</v-col>
                          <v-col class="text-right font-weight-bold">{{ currencyFormat(taxTotal) }}</v-col>
                        </v-row>
                        <hr>
                        <v-row class="mt-1">
                          <v-col class="text-right font-weight-bold">Total</v-col>
                          <v-col class="text-right font-weight-bold">{{ currencyFormat(subTotal + taxTotal) }}</v-col>
                        </v-row>
                        <v-row>
                          <v-col class="text-right font-weight-bold subtitle-1">{{ $t('balance_due') }}</v-col>
                          <v-col class="text-right font-weight-bold">{{ currencyFormat(data.balance) }}</v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- <v-col sm="12" md="3">
                    <span class="text-uppercase subtitle-1 font-weight-black ml-10">{{ $t('invoice') }} {{ $t('activity') }}</span>
                    <v-timeline
                      dense
                      align-top
                    >
                      <v-timeline-item
                        v-for="(activity, i) in invoiceActivity"
                        :key="activity.status"
                        small
                        color="white"
                      >
                        <template v-slot:icon>
                          <v-icon :color="activity.isActive ? '#0b6084' : '#b3cfe0'">mdi-record-circle-outline</v-icon>
                        </template>
                        <span class="activity-color font-weight-bold" :class="{'in-active-activity': !activity.isActive}">
                          {{ activity.status }}
                        </span>
                        <br>
                        <small v-if="activity.isActive" class="activity-color">{{ getMDYFormat(activity.date) }}</small>
                        <div v-if="activity.isPayment">
                          <span class="activity-color">
                            {{ activity.description }}
                          </span>
                          <div>
                            <a
                              href="#"
                              class="text-decoration-none"
                            >
                              {{ $t('view') }} {{ $t('payment') }}
                            </a>
                          </div>
                        </div>
                      </v-timeline-item>
                    </v-timeline>
                  </v-col> -->
                </v-row>
              </v-card-text>

              <!-- <v-card-text v-else class="mb-16">
                <v-card width="300" class="mx-auto text-center my-8" flat v-if="payments.length === 0">
                  <v-card-text>
                    <span class="black--text text-h5">{{ $t('no') }} {{ $t('payments') }}</span>
                    <p class="font-weight-medium">{{ $t('invoice_no_payment') }}</p>
                  </v-card-text>
                </v-card>

                <v-simple-table class="v-table-middle-align" v-else>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-uppercase labelcolor--text">{{ $t('payment') }} {{ $t('date') }}</th>
                        <th class="text-uppercase labelcolor--text">{{ $t('payment') }} {{ $t('method') }}</th>
                        <th class="text-uppercase labelcolor--text">{{ $t('deposit') }} {{ $t('to') }}</th>
                        <th class="text-uppercase labelcolor--text text-right">{{ $t('original') }} {{ $t('amount') }}</th>
                        <th class="text-uppercase labelcolor--text text-right">{{ $t('payment') }}</th>
                        <th class="text-uppercase labelcolor--text text-right">{{ $t('open') }} {{ $t('balance') }}</th>
                        <th v-if="data.status !== 'paid'"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(payment, i) in payments" :key="i">
                        <td>{{ getMonthNameDayYearFormat(payment.paymentDate) }}</td>
                        <td>{{ payment.paymentMethod }}</td>
                        <td>{{ payment.depositTo }}</td>
                        <td class="text-right">{{ currencyFormat(payment.originalAmount) }}</td>
                        <td class="text-right">{{ currencyFormat(payment.paymentAmount) }}</td>
                        <td class="text-right">{{ currencyFormat(payment.openBalance) }}</td>
                        <td v-if="data.status !== 'paid'">
                          <v-btn text outlined small height="32" width="32" color="#0171a1" @click="onEditPaymentForm(payment)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text> -->
            </v-card>

            <invoice-form :open="showEditModal" :form-values="formData" :is-edit-mode="true" @toggle="onToggleEditModal"></invoice-form>
            <invoice-delete :open="showDeleteInvoiceModal" :form-data="data" @toggle="onToggleDeleteInvoice"></invoice-delete>
            <!-- <receive-payment-form :open="showPaymentModal" :form-values="paymentData" :invoice="data" :is-edit-mode="isPaymentModalEditMode" @toggle="onTogglePaymentModal"></receive-payment-form> -->

        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>

import InvoiceForm from '../components/InvoiceForm.vue';
import InvoiceDelete from '../components/InvoiceDeleteModal.vue';
// import ReceivePaymentForm from '../components/ReceivePaymentForm.vue';

import AkauntingService from '@/services/akaunting/akaunting.service';
import { apiErrorMessage } from '@/utils/general';

export default {
	components: {
		InvoiceForm,
    InvoiceDelete,
    // ReceivePaymentForm,
	},
	data() {
		return {
      tabStatus: 0,
			showEditModal: false,
			showPaymentModal: false,
			showDeleteModal: false,
      isPaymentModalEditMode: false,
      paymentData: {},
			data: {
        items: [],
      },
      payments: [{
        id: 1,
        paymentDate: '2021-06-04',
        paymentMethod: 'Cash',
        depositTo: 'Checking (9876)',
        originalAmount: 6879.99,
        paymentAmount: 6879.99,
        openBalance: 0,
      }],
      invoiceActivity: [
        {
          status: 'Opened',
          date: '2021-12-03',
          isActive: true,
        }, {
          status: 'Sent',
          date: '2021-12-01',
          isActive: true,
        }, {
          status: 'paid',
          date: '2021-11-30',
          isActive: false,
        }, {
          status: 'Payment Received',
          date: '2021-11-28',
          isActive: true,
          description: '$1,200.00 out of $6,210.00',
          isPayment: true,
        }, {
          status: 'Deposited',
          date: '2021-11-28',
          isActive: false,
        }
      ],
      isLoading: false,
      showDeleteInvoiceModal: false,
      formData: null,
		}
	},
  created() {
    this.fetchInvoice();
  },
  computed: {
    //

    statusColor() {
      if(this.data.status) {
        switch(this.data.status.toLowerCase()) {
          case 'overdue': 
            return { color: '#e32d2d', background: '#fff2f2' };
          case 'partial':
          return { color: '#7cdab8', background: '#ecffed' };
          case 'open':
          return { color: '#0171a1', background: '#cbf1ff' };
          case 'draft':
            return { color: '#819fb2', background: '#efefef' };
        }
      }
			return { color: '#fff', background: '#fff' };
		},

    breadCrumb() {
      return [
        {
          text: this.$t('side_menu_accounting'),
          disabled: true,
          href: '#',
        },
        {
          text: this.$t('side_menu_invoices'),
          disabled: false,
          href: '/accounting/invoices',
        },
        {
          text: this.invoiceNumber || '',
          disabled: true,
          href: '#',
        },
      ];
    },

		invoiceNumber: {
			get() {
				return this.data.document_number || '';
			}
		},

		subTotal(){
      if(this.items && this.items.length) {
				return this.items.reduce((c, n) => c + (Number(n.quantity) * Number(n.price) ) ,0);
			}
			return 0;
		},

		taxTotal() {
			return 0;
		},

    invoiceId() {
      return this.$route.params.id;
    },

    category() {
      return this.data.category_ref ? JSON.parse(this.data.category_ref).name : '';
    },

    items() {
      return this.data.items_ref ? JSON.parse(this.data.items_ref) : [];
    },

    customerCurrencySymbol() {
      return this.data?.currency?.data?.symbol || '';
    },

    attachedFiles() {
      return this.data?.attachment || [];
    },

    currency() {
      return this.data?.currency?.data?.name;
    }

  },
  methods: {
    //

    async fetchInvoice() {
      if(this.isLoading) {
        return;
      }
      this.isLoading = true;
      try {
        const records = await AkauntingService.getInvoice(this.invoiceId);
        this.data = records.data?.data?.data || {};
      } catch(error) {
        apiErrorMessage(error);
      } finally {
        this.isLoading = false;
      }
    },

    onEditPaymentForm(data) {
      this.paymentData = JSON.parse(JSON.stringify(data));
      this.showPaymentModal = true;
    },

    getMonthNameDayYearFormat(dateString) {
      if(!dateString) {
        return '';
      }
      const months = ['Jan', 'Feb', 'March', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const _date = new Date(dateString);
      return `${months[ _date.getMonth() ]} ${_date.getDate()}, ${_date.getFullYear()}`;
    },

    currencyFormat(amount) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: this.data?.currency?.data?.code || 'USD' }).format(amount);
    },

    getMDYFormat(dateString) {
      const date = new Date(dateString);
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    },

    onTogglePaymentModal() {
      this.showPaymentModal = !this.showPaymentModal;
      if(!this.showPaymentModal) {
        this.paymentData = {};
      }
    },

		onToggleEditModal(options = {}) {
			this.showEditModal = !this.showEditModal;
			this.formData = null;

			if(this.showEditModal) {
				this.formData = JSON.parse(JSON.stringify(this.data));
			}

			if(options.updated) {
			  this.$notify({
          title: 'Message',
          message: options.message || 'Updated.',
          type: 'success'
         });
			  this.fetchInvoice();
      }
		},

    onToggleDeleteInvoice(data = {}, options = {}) {
      this.showDeleteInvoiceModal = !this.showDeleteInvoiceModal;
      if(options.deleted) {
        this.$notify({
          title: 'Message',
          message: options.message || 'Deleted.',
          type: 'success'
         });
				 this.$router.push('/accounting/invoices');
      }
    },

    onChangeTab() {
      //
    }

  }

};
</script>
<style lang="scss" scoped>
	$button-bg-color: #0171a1;
	.vue-app {
		background: none;
	}

	hr {
		border-color: #ffffff !important;
	}
	.btn-primary {
		background-color: $button-bg-color !important;
		color: #fff !important;
	}

  .activity-color {
    color: #0b6084;
  }

  .activity-color.in-active-activity {
    color: #b3cfe0;
  }

  .border-right {
    border-right: 1px solid #ddd;
  }

</style>
<style lang="scss">
	.v-table-middle-align {
		width: 100%;
    td, th {
      vertical-align: middle !important;;
    }
  }
</style>